import base, { Config, StaticMapLayerOrFolder } from "../../config";

import FEMA_NFHL from "../../layers/dynamic/FEMA_NFHL";
import { LatLng } from "leaflet";
import { camerasTusayan } from "../../layers/misc/cameras";
import notEmpty from "../../shared/notEmpty";

const staticLayers: StaticMapLayerOrFolder[] = [

].filter(notEmpty);

const config: Config = {
  ...base,
  ...{
    title: "Tusayan",
    center: new LatLng(35.972787, -112.126556),
    zoom: 14,
    basemap: "ImageryLabels",
    agencyPassList: ["Tusayan"],
    layers: {
      static: staticLayers,
      dynamic: [FEMA_NFHL],
      misc: [
        {
          title: "Webcams",
          layers: [
            { name: "Tusayan Webcams", cameras: camerasTusayan },
          ],
        },
      ],
      lightning: false,
      cbrfc: false,
    },
    markers: {
      ...base.markers,
      ...{
        precip: {
          ...base.markers.precip,
          ...{ dotDisplayZoomLevel: false }
        }
      }
    }

  },
};

export default config;
